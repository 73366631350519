import classNames from 'classnames'
import { Form, Button } from 'react-bootstrap'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { SideBarHeader } from 'components/SideBar'
import { CloseIcon } from 'components/icons'
import { TooltipContainer } from 'components'
import { SURVEY_PANELS } from 'helpers'

export const SurveyPanel = ({ label, options = [] }) => {
  const { panel, menu, surveyId } = useParams()
  const navigate = useNavigate()

  // sets the first option as the default option when the panel is opened.
  useEffect(() => {
    if (!panel || panel === SURVEY_PANELS.structure.panel || !surveyId) {
      return
    }

    const firstOption = options[0]
    navigate(`/survey/${surveyId}/${panel}/${firstOption?.menu}`)
  }, [panel, options.length])

  const handleClose = () => {
    navigate(`/survey/${surveyId}`)
  }

  const handleOnOptionClick = (option = {}) => {
    if (option.redirect) {
      window.open(option.redirect, '_self')
    } else {
      navigate(`/survey/${surveyId}/${panel}/${option.menu}`)
    }
  }

  return (
    <div data-testid="survey-menu-panel" className="d-flex h-100">
      <div className="survey-structure px-2">
        <div className={'survey-menu'}>
          <SideBarHeader className="right-side-bar-header primary">
            {label}
            <Button
              variant="link"
              className="p-0 btn-close-lime"
              onClick={handleClose}
              data-testid="btn-close-survey-menu-panel"
            >
              <CloseIcon className="text-black fill-current" />
            </Button>
          </SideBarHeader>
          {options.map((option, idx) => {
            return (
              <TooltipContainer
                key={`${idx}-${option.menu}`}
                tip={option.disabledTip}
                showTip={option.disabled}
                placement="right"
              >
                <div
                  onClick={() => handleOnOptionClick(option)}
                  className={classNames(
                    `px-4 py-3 d-flex align-items-center cursor-pointer rounded`,
                    {
                      'focus-bg-purple': menu === option.menu,
                      'disabled': option.disabled,
                    }
                  )}
                >
                  <Form.Label
                    className={classNames(`cursor-pointer mb-0`, {
                      'text-white': menu === option.menu,
                    })}
                  >
                    {option.label}
                  </Form.Label>
                </div>
              </TooltipContainer>
            )
          })}
        </div>
      </div>
    </div>
  )
}
