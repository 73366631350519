import React, { useEffect, useRef, useState } from 'react'
import ListGroup from 'react-bootstrap/ListGroup'
import classNames from 'classnames'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Input } from 'components/UIComponents'
import searchIcon from 'assets/icons/search-icon.svg'

import { TableIcon, ClockArrowIcon, AddIcon } from 'components/icons'

import { QuestionTypeInfo } from '../QuestionTypes'
import {
  QuestionInserterRankIcon,
  QuestionInserterDataIcon,
  QuestionInserterTextIcon,
  QuestionInserterNumberIcon,
  QuestionInserterSingleChoiceIcon,
  QuestionInserterMultipleChoiceIcon,
} from 'components/icons'

export const QuestionTypeSelector = ({
  callBack,
  disableAddingQuestions = false,
  attributeTypeSelector = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1)
  const searchInputRef = useRef(null)
  const listRef = useRef(null)
  const listItemRef = useRef(null)
  const [searchedQuestionTypeOrGroup, setSearchedQuestionTypeOrGroup] =
    useState([])

  const groupListData = {
    value: QuestionTypeInfo.QUESTION_GROUP.type,
    label: QuestionTypeInfo.QUESTION_GROUP.title,
    theme: QuestionTypeInfo.QUESTION_GROUP.theme,
  }
  const itemsList = [
    {
      title: 'Single Choice',
      icon: <QuestionInserterSingleChoiceIcon />,
      items: [
        {
          value: QuestionTypeInfo.LIST_RADIO.type,
          label: QuestionTypeInfo.LIST_RADIO.title,
          theme: QuestionTypeInfo.LIST_RADIO.theme,
          disabled: disableAddingQuestions,
        },
        {
          value: QuestionTypeInfo.LIST_RADIO_WITH_COMMENT.type,
          label: QuestionTypeInfo.LIST_RADIO_WITH_COMMENT.title,
          theme: QuestionTypeInfo.LIST_RADIO_WITH_COMMENT.theme,
          disabled: disableAddingQuestions,
        },
        {
          value: QuestionTypeInfo.SINGLE_CHOICE_LIST_IMAGE_SELECT.type,
          label: QuestionTypeInfo.SINGLE_CHOICE_LIST_IMAGE_SELECT.title,
          theme: QuestionTypeInfo.SINGLE_CHOICE_LIST_IMAGE_SELECT.theme,
          disabled: disableAddingQuestions,
          hidden: !process.env.REACT_APP_DEV_MODE,
        },
        {
          value: QuestionTypeInfo.FIVE_POINT_CHOICE.type,
          label: QuestionTypeInfo.FIVE_POINT_CHOICE.title,
          theme: QuestionTypeInfo.FIVE_POINT_CHOICE.theme,
          disabled: disableAddingQuestions,
        },
        {
          value: QuestionTypeInfo.SINGLE_CHOICE_DROPDOWN.type,
          label: QuestionTypeInfo.SINGLE_CHOICE_DROPDOWN.title,
          theme: QuestionTypeInfo.SINGLE_CHOICE_DROPDOWN.theme,
          disabled: disableAddingQuestions,
        },
        {
          value: QuestionTypeInfo.SINGLE_CHOICE_BUTTONS.type,
          label: QuestionTypeInfo.SINGLE_CHOICE_BUTTONS.title,
          theme: QuestionTypeInfo.SINGLE_CHOICE_BUTTONS.theme,
          disabled: disableAddingQuestions,
        },
      ],
    },
    {
      title: 'Multiple Choice',
      icon: <QuestionInserterMultipleChoiceIcon />,

      items: [
        {
          value: QuestionTypeInfo.MULTIPLE_CHOICE.type,
          label: QuestionTypeInfo.MULTIPLE_CHOICE.title,
          theme: QuestionTypeInfo.MULTIPLE_CHOICE.theme,
          disabled: disableAddingQuestions,
        },
        {
          value: QuestionTypeInfo.MULTIPLE_CHOICE_WITH_COMMENTS.type,
          label: QuestionTypeInfo.MULTIPLE_CHOICE_WITH_COMMENTS.title,
          theme: QuestionTypeInfo.MULTIPLE_CHOICE_WITH_COMMENTS.theme,
          disabled: disableAddingQuestions,
        },
        {
          value: QuestionTypeInfo.MULTIPLE_CHOICE_BUTTONS.type,
          label: QuestionTypeInfo.MULTIPLE_CHOICE_BUTTONS.title,
          theme: QuestionTypeInfo.MULTIPLE_CHOICE_BUTTONS.theme,
          disabled: disableAddingQuestions,
        },
        {
          value: QuestionTypeInfo.MULTIPLE_CHOICE_IMAGE_SELECT.type,
          label: QuestionTypeInfo.MULTIPLE_CHOICE_IMAGE_SELECT.title,
          theme: QuestionTypeInfo.MULTIPLE_CHOICE_IMAGE_SELECT.theme,
          disabled: disableAddingQuestions,
          hidden: !process.env.REACT_APP_DEV_MODE,
        },
      ],
    },
    {
      title: 'Ranking & Rating',
      icon: <QuestionInserterRankIcon />,
      items: [
        {
          value: QuestionTypeInfo.RANKING.type,
          label: QuestionTypeInfo.RANKING.title,
          theme: QuestionTypeInfo.RANKING.theme,
          disabled: disableAddingQuestions,
          hidden: !process.env.REACT_APP_DEV_MODE,
        },
        {
          value: QuestionTypeInfo.RANKING_ADVANCED.type,
          label: QuestionTypeInfo.RANKING_ADVANCED.title,
          theme: QuestionTypeInfo.RANKING_ADVANCED.theme,
          disabled: disableAddingQuestions,
        },
        {
          value: QuestionTypeInfo.RATING.type,
          label: QuestionTypeInfo.RATING.title,
          theme: QuestionTypeInfo.RATING.theme,
          disabled: disableAddingQuestions,
          hidden: !process.env.REACT_APP_DEV_MODE,
        },
        {
          value: QuestionTypeInfo.GENDER.type,
          label: QuestionTypeInfo.GENDER.title,
          theme: QuestionTypeInfo.GENDER.theme,
          disabled: disableAddingQuestions,
        },
        {
          value: QuestionTypeInfo.YES_NO.type,
          label: QuestionTypeInfo.YES_NO.title,
          theme: QuestionTypeInfo.YES_NO.theme,
          disabled: disableAddingQuestions,
        },
      ],
    },
    {
      title: 'Dates & Data',
      icon: <QuestionInserterDataIcon />,

      items: [
        {
          value: QuestionTypeInfo.BROWSER_DETECTION.type,
          label: QuestionTypeInfo.BROWSER_DETECTION.title,
          theme: QuestionTypeInfo.BROWSER_DETECTION.theme,
          disabled: disableAddingQuestions,
        },
        {
          value: QuestionTypeInfo.DATE_TIME.type,
          label: QuestionTypeInfo.DATE_TIME.title,
          theme: QuestionTypeInfo.DATE_TIME.theme,
          disabled: disableAddingQuestions,
        },
        {
          value: QuestionTypeInfo.FILE_UPLOAD.type,
          label: QuestionTypeInfo.FILE_UPLOAD.title,
          theme: QuestionTypeInfo.FILE_UPLOAD.theme,
          disabled: disableAddingQuestions,
        },
      ],
    },
    {
      title: 'Text',
      icon: <QuestionInserterTextIcon />,

      items: [
        {
          value: QuestionTypeInfo.SHORT_TEXT.type,
          label: QuestionTypeInfo.SHORT_TEXT.title,
          theme: QuestionTypeInfo.SHORT_TEXT.theme,
          disabled: disableAddingQuestions,
        },
        {
          value: QuestionTypeInfo.LONG_TEXT.type,
          label: QuestionTypeInfo.LONG_TEXT.title,
          theme: QuestionTypeInfo.LONG_TEXT.theme,
          disabled: disableAddingQuestions,
        },
        {
          value: QuestionTypeInfo.MULTIPLE_SHORT_TEXTS.type,
          label: QuestionTypeInfo.MULTIPLE_SHORT_TEXTS.title,
          theme: QuestionTypeInfo.MULTIPLE_SHORT_TEXTS.theme,
          disabled: disableAddingQuestions,
        },
      ],
    },

    {
      title: 'Number',
      icon: <QuestionInserterNumberIcon />,

      items: [
        {
          value: QuestionTypeInfo.MULTIPLE_NUMERICAL_INPUTS.type,
          label: QuestionTypeInfo.MULTIPLE_NUMERICAL_INPUTS.title,
          theme: QuestionTypeInfo.MULTIPLE_NUMERICAL_INPUTS.theme,
          disabled: disableAddingQuestions,
        },

        {
          value: QuestionTypeInfo.TEXT_DISPLAY.type,
          label: QuestionTypeInfo.TEXT_DISPLAY.title,
          theme: QuestionTypeInfo.TEXT_DISPLAY.theme,
          disabled: disableAddingQuestions,
        },

        {
          value: QuestionTypeInfo.EQUATION.type,
          label: QuestionTypeInfo.EQUATION.title,
          theme: QuestionTypeInfo.EQUATION.theme,
          disabled: disableAddingQuestions,
          hidden: !process.env.REACT_APP_DEV_MODE,
        },
      ],
    },
    {
      title: 'Array',
      icon: <TableIcon />,

      items: [
        {
          value: QuestionTypeInfo.ARRAY.type,
          label: QuestionTypeInfo.ARRAY.title,
          theme: QuestionTypeInfo.ARRAY.theme,
          disabled: disableAddingQuestions,
        },
        {
          value: QuestionTypeInfo.ARRAY_TEXT.type,
          label: QuestionTypeInfo.ARRAY_TEXT.title,
          theme: QuestionTypeInfo.ARRAY_TEXT.theme,
          disabled: disableAddingQuestions,
        },
        {
          value: QuestionTypeInfo.ARRAY_NUMBERS.type,
          label: QuestionTypeInfo.ARRAY_NUMBERS.title,
          theme: QuestionTypeInfo.ARRAY_NUMBERS.theme,

          disabled: disableAddingQuestions,
        },
        {
          value: QuestionTypeInfo.ARRAY_COLUMN.type,
          label: QuestionTypeInfo.ARRAY_COLUMN.title,
          theme: QuestionTypeInfo.ARRAY_COLUMN.theme,
          disabled: disableAddingQuestions,
        },
        {
          value: QuestionTypeInfo.ARRAY_DUAL_SCALE.type,
          label: QuestionTypeInfo.ARRAY_DUAL_SCALE.title,
          theme: QuestionTypeInfo.ARRAY_DUAL_SCALE.theme,
          disabled: disableAddingQuestions,
        },
      ],
    },
  ]
  const filteredItemsLists = itemsList.reduce((result, currentItem) => {
    const filteredItems = currentItem.items.filter(
      (item) => item.label.toLowerCase().includes(searchTerm.toLowerCase())
      // && !item.hidden
    )

    if (filteredItems.length) {
      result.push({
        title: currentItem.title,
        icon: currentItem.icon,
        items: filteredItems,
      })
    }

    return result
  }, [])

  useEffect(() => {
    searchInputRef.current.focus({ preventScroll: true })

    const itemsLength = filteredItemsLists.reduce(
      (acc, curr) => acc + curr.items.length,
      0
    )

    const handleKeyDown = (event) => {
      if (event.key === 'ArrowUp') {
        setSelectedItemIndex((prevSelectedItem) =>
          Math.max(prevSelectedItem - 1, -1)
        )
      } else if (event.key === 'ArrowDown') {
        setSelectedItemIndex((prevSelectedItem) =>
          Math.min(prevSelectedItem + 1, itemsLength - 1)
        )
      } else if (event.key === 'Enter') {
        // loop into the items until we find the selected item index.
        let index = 0
        for (let i = 0; i < filteredItemsLists.length; i++) {
          const listItems = filteredItemsLists[i].items
          for (let j = 0; j < listItems.length; j++) {
            const item = listItems[j]
            if (index === selectedItemIndex) {
              const temp = [...searchedQuestionTypeOrGroup]
              if (temp.length > 4) {
                temp.shift()
              }
              temp.push(searchTerm)
              setSearchedQuestionTypeOrGroup([...temp])
              callBack({
                type: item.value,
                questionThemeName: item.theme,
              })
            }

            index++
          }
        }
      }
    }

    // scroll to the selected item
    if (listRef.current && listItemRef.current) {
      const listGroupRect = listRef.current.getBoundingClientRect()
      const selectedListItemRect = listItemRef.current.getBoundingClientRect()

      if (selectedListItemRect.bottom > listGroupRect.bottom) {
        listRef.current.scrollTop +=
          selectedListItemRect.bottom - listGroupRect.bottom + 10
      } else if (selectedListItemRect.top < listGroupRect.top) {
        listRef.current.scrollTop -=
          listGroupRect.top - selectedListItemRect.top
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [selectedItemIndex, filteredItemsLists, callBack])

  // Keeps a running count on how many questions we have

  const renderData = (data) => {
    const rows = []
    for (let i = 0; i < data.length; i += 3) {
      let previousItemsTotal = 0

      const rowItems =
        data.slice(i, i + 3).length === 3
          ? data.slice(i, i + 3)
          : [...data.slice(i, i + 3), { items: [] }]
      const cols = rowItems.map((list, listGroupIndex) => {
        previousItemsTotal += list.items.length
        const offsetIndex = previousItemsTotal - list.items.length

        return (
          <Col key={listGroupIndex + list.title + 'question-type-selector'}>
            <div className="d-flex gap-2  align-items-center">
              {list.icon}
              <span className="fw-bold m-0 label-s">{list.title}</span>
            </div>
            <ListGroup className="my-2" variant="flush">
              {list?.items.map((listItem, listItemIndex) => {
                return (
                  <ListGroup.Item
                    key={'list-item' + listItemIndex}
                    onClick={() => {
                      if (listItem.hidden || listItem.disabled) {
                        return
                      }
                      const temp = [...searchedQuestionTypeOrGroup]
                      temp.push(searchTerm)
                      setSearchedQuestionTypeOrGroup([...temp])
                      callBack({
                        type: listItem.value,
                        questionThemeName: listItem.theme,
                      })
                    }}
                    className={classNames('px-2', {
                      'focus-element':
                        selectedItemIndex === offsetIndex + listItemIndex,
                      'disabled': listItem.disabled,
                      'd-none': listItem.hidden,
                    })}
                    ref={
                      selectedItemIndex === offsetIndex + listItemIndex
                        ? listItemRef
                        : null
                    }
                    data-testid={`question-type-${listItem.theme}`}
                  >
                    {/* {listItem.icon} */}
                    <span
                      data-testid="question-type-selector-label"
                      className="text-primary label-s"
                    >
                      {listItem.label}
                    </span>
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </Col>
        )
      })
      rows.push(
        <Row
          className={classNames('mb-4', {
            'flex-column': attributeTypeSelector,
          })}
          key={i}
        >
          {cols}
        </Row>
      )
    }
    return rows
  }
  return (
    <div className="question-type-selector border border-primary rounded border-2 shadow-sm position-relative">
      <div className="search" style={{ marginBottom: 26, height: 52 }}>
        <Input
          value={searchTerm}
          placeholder="Search for a question type"
          id="question-type-search"
          inputRef={searchInputRef}
          dataTestId={'question-type-search'}
          Icon={searchIcon}
          onChange={(e) => {
            setSearchTerm(e.target.value)
            setSelectedItemIndex(-1)
          }}
          inputClass="question-inserter-search"
          className="h-100"
          autoComplete={false}
        />
      </div>
      <div ref={listRef} className="list d-flex">
        <div
          style={{ width: '25%', minHeight: '300px' }}
          className={classNames('d-flex flex-column justify-content-between', {
            'd-none': attributeTypeSelector,
          })}
        >
          <div>
            <ListGroup variant="flush">
              <ListGroup.Item className="add-question-group">
                <AddIcon className="text-primary fill-current ms-1" />
                <span
                  className="text-primary label-s ms-1"
                  onClick={() =>
                    callBack({
                      type: groupListData.value,
                      questionThemeName: groupListData.theme,
                    })
                  }
                >
                  Add question group
                </span>
              </ListGroup.Item>
            </ListGroup>
            {process.env.REACT_APP_DEV_MODE && (
              <div className="d-flex align-items-center gap-1">
                <ClockArrowIcon />
                <span className="fw-bold m-0 label-s">Recently Used</span>
              </div>
            )}
            <ListGroup className="my-2" variant="flush">
              {searchedQuestionTypeOrGroup?.map((searchedText, index) => (
                <span
                  className="text-primary text-normal cursor-pointer"
                  onClick={() => setSearchTerm(searchedText)}
                  key={`${index}-searched-text`}
                >
                  {searchedText}
                </span>
              ))}
            </ListGroup>
          </div>
        </div>

        <Container>{renderData(filteredItemsLists)}</Container>
      </div>
    </div>
  )
}
