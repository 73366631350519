import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import { Toaster } from 'react-hot-toast'
import dayjs from 'dayjs'
import posthog from 'posthog-js'

import { Survey } from 'components/Survey'
import { ExclamationMark, EyeIcon, XIcon } from 'components/icons'
import { SurveySettings } from 'components/SurveySettings/SurveySettings'
import { QuestionTypeInfo } from 'components/QuestionTypes'
import { SiteSettingsService } from 'services'
import {
  useAppState,
  useBuffer,
  useElementClick,
  useErrors,
  useFocused,
  useSurvey,
  useSurveyGroupsService,
} from 'hooks'
import {
  EntitiesType,
  OperationsBuffer,
  STATES,
  SURVEY_PANELS,
  Toast,
  apiUrl,
  errorToast,
  filterArray,
  getGroupById,
  getQuestionById,
  processValidationErrorsAndUpdateOperations,
  replaceTempIdsInSurveyAndBuffer,
} from 'helpers'
import { queryClient } from 'queryClient'

import { TopBar } from './TopBar/TopBar'
import { LeftSideBar } from './LeftSideBar/LeftSideBar'
import { RightSideBar } from './RightSideBar/RightSideBar'
import { FeedbackPopup } from './FeedbackPopup'

export const Editor = () => {
  const { surveyId, panel, menu } = useParams()
  const location = useLocation()
  const { survey = {}, surveyPatch, fetchSurvey, update } = useSurvey(surveyId)
  const { errors, setErrorsFromPatchResponse, clearErrors } = useErrors()
  const { operationsBuffer, addToBuffer, clearBuffer } = useBuffer()
  const { unFocus, setFocused } = useFocused()
  const { surveyGroupsService } = useSurveyGroupsService()
  const [hasNavigated, setHasNavigated] = useState(false)

  const [, setSiteSettings] = useAppState(STATES.SITE_SETTINGS, {})
  const [, setSurveyGroups] = useAppState(STATES.SURVEY_GROUPS, {})
  const [, setHelpersSettings] = useAppState(STATES.HELPER_SETTINGS, {})
  const [, setSaveStatue] = useAppState(STATES.SAVE_STATUS, '')
  const [, setSurveyLanguage] = useAppState(STATES.SURVEY_LANGUAGE)
  const [hasSurveyUpdatePermission, setHasSurveyUpdatePermission] = useAppState(
    STATES.HAS_SURVEY_UPDATE_PERMISSION
  )
  const [isPatchSurveyRunning, setIsPatchSurveyRunning] = useAppState(
    STATES.IS_PATCH_SURVEY_RUNNING,
    false
  )
  const [isAddingQuestionOrGroup, setIsAddingQuestionOrGroup] = useAppState(
    STATES.IS_ADDING_QUESTION_OR_GROUP,
    false
  )

  const [currentOpenPanel = {}, setCurrentOpenPanel] = useAppState(
    STATES.CURRENT_OPEN_PANEL,
    {}
  )
  const [isSurveyActive] = useAppState(STATES.IS_SURVEY_ACTIVE, false)
  const [, setIsSurveyShareMenuOpen] = useAppState(
    STATES.IS_SURVEY_SHARE_MENU_OPEN,
    false
  )

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (operationsBuffer.getOperations()?.length > 0) {
        event.preventDefault()
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [operationsBuffer])

  useEffect(() => {
    clearBuffer()
    clearErrors()
    setHelpersSettings({})
    setIsPatchSurveyRunning(false)
    fetchSurvey(surveyId)

    posthog.capture('editor_open_survey', {
      surveyId: surveyId,
    })
  }, [surveyId])

  useEffect(() => {
    if (panel) {
      const panelInfo = SURVEY_PANELS[panel]

      if (panelInfo) {
        setCurrentOpenPanel({
          ...panelInfo,
          menu: menu,
        })
      }
    } else if (currentOpenPanel.panel !== SURVEY_PANELS.structure.panel) {
      setCurrentOpenPanel({})
    }
  }, [surveyId, menu, panel])

  useEffect(() => {
    // prevent browser auto scroll onPageLoad
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual'
    }

    if (!hasSurveyUpdatePermission || !survey.sid || hasNavigated) {
      return
    }

    setHasNavigated(true)

    const query = new URLSearchParams(location.search)
    const queryKey = query.keys().next().value
    const queryValue = +query.get(queryKey)

    if (queryKey === EntitiesType.welcomeScreen) {
      setFocused(
        { info: QuestionTypeInfo.WELCOME_SCREEN },
        undefined,
        undefined,
        false
      )
    } else if (queryKey === EntitiesType.endScreen) {
      setFocused(
        { info: QuestionTypeInfo.END_SCREEN },
        undefined,
        undefined,
        false
      )
    } else if (queryValue) {
      if (queryKey === EntitiesType.question) {
        const questionInfo = getQuestionById(queryValue, survey)
        setFocused(
          questionInfo.question,
          questionInfo.groupIndex,
          questionInfo.questionIndex,
          false
        )
      } else if (queryKey === EntitiesType.group) {
        const groupInfo = getGroupById(queryValue, survey)
        setFocused(groupInfo.group, groupInfo.index, undefined, false)
      }
    } else {
      unFocus()
    }
  }, [survey.sid])

  useEffect(() => {
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const messages = Object.values(errors[key])
        for (const message of messages) {
          if (typeof message === 'string') {
            Toast({
              message: message,
              leftIcon: <ExclamationMark />,
              rightIcon: <XIcon />,
            })
          }
        }
      }
    }
  }, [errors])

  useEffect(() => {
    setHasSurveyUpdatePermission(survey.hasSurveyUpdatePermission || true)
  }, [survey.sid, survey.hasSurveyUpdatePermission])

  useEffect(() => {
    setSurveyLanguage(survey.language)
  }, [survey.language])

  const handleClickInside = () => {
    unFocus()
  }

  const ref = useElementClick(handleClickInside, false)

  useEffect(() => {
    if (
      process.env.REACT_APP_DEMO_MODE === 'true' ||
      process.env.STORYBOOK_DEV === 'true'
    ) {
      setSiteSettings({ siteName: 'LimeSurvey', timezone: 'UTC' })
      return
    }

    const siteSettingsService = new SiteSettingsService(apiUrl())

    siteSettingsService.getSiteData().then((result) => {
      setSiteSettings(result.data)
    })

    surveyGroupsService.getSurveyGroups().then((result) => {
      setSurveyGroups(result.data)
    })
  }, [])

  useEffect(() => {
    if (process.env.REACT_APP_DEMO_MODE === 'true' || isPatchSurveyRunning) {
      return
    }

    const { validArray: readyOperations, filteredArray: delayedOperations } =
      filterArray(
        operationsBuffer.getOperations(),
        operationsBuffer.isOperationReadyForPatch
      )

    const beforeCallback = () => {
      operationsBuffer.clearBuffer()
      clearBuffer()
      setIsPatchSurveyRunning(true)
    }

    const thenCallback = (result) => {
      const currentOperationsBuffer = new OperationsBuffer(
        queryClient.getQueryData([STATES.BUFFER])
      )

      delayedOperations.map((delayedOperation) => {
        operationsBuffer.addOperation(delayedOperation, false)
      })

      const questionsTempIdMapping = result.tempIdMapping?.questionsMap
      const GroupsTempIdMapping = result.tempIdMapping?.questionGroupsMap
      const answersTempIdMapping = result.tempIdMapping?.answersMap

      let mapResult = replaceTempIdsInSurveyAndBuffer(
        GroupsTempIdMapping,
        EntitiesType.group,
        survey,
        operationsBuffer
      )

      mapResult = replaceTempIdsInSurveyAndBuffer(
        questionsTempIdMapping,
        EntitiesType.question,
        mapResult.survey,
        mapResult.operationsBuffer
      )

      mapResult = replaceTempIdsInSurveyAndBuffer(
        answersTempIdMapping,
        EntitiesType.answer,
        mapResult.survey,
        mapResult.operationsBuffer
      )

      let validationErrors = result.validationErrors
        ? result.validationErrors
        : []

      let exceptionErrors = result.exceptionErrors ? result.exceptionErrors : []
      const readyOperationsBuffer = new OperationsBuffer(readyOperations)

      let errorsResult = processValidationErrorsAndUpdateOperations(
        validationErrors,
        readyOperationsBuffer,
        mapResult.operationsBuffer
      )
      validationErrors = errorsResult.errors

      errorsResult = processValidationErrorsAndUpdateOperations(
        exceptionErrors,
        readyOperationsBuffer,
        errorsResult.operationsBuffer
      )
      exceptionErrors = errorsResult.errors

      const updatedSurveyWithCurrentOperations =
        currentOperationsBuffer.applyOperations(mapResult.survey)

      update({ ...updatedSurveyWithCurrentOperations })

      errorsResult.operationsBuffer.getOperations().forEach((operation) => {
        addToBuffer(operation, false)
      })

      setSaveStatue(`Saved at ${dayjs(new Date().getTime()).format('hh:mm')}`)
      setErrorsFromPatchResponse([...validationErrors, ...exceptionErrors])

      if (!result.operationsApplied) {
        errorToast(
          'Sorry, we encountered an issue while saving the changes. Please try refreshing the page!'
        )
      }
    }

    const catchCallback = (error) => {
      const updatedOperation = readyOperations.map((operation) => ({
        ...operation,
        error: true,
      }))

      updatedOperation.forEach((operation) => {
        addToBuffer(operation, false)
      })

      setErrorsFromPatchResponse([...updatedOperation])
      setSaveStatue(
        `Error happened ${dayjs(new Date().getTime()).format('hh:mm')}`
      )

      errorToast(error.message)
    }

    const finallyCallback = () => {
      setIsPatchSurveyRunning(false)
    }

    surveyPatch(
      readyOperations,
      beforeCallback,
      thenCallback,
      finallyCallback,
      catchCallback
    )
  }, [operationsBuffer.bufferHash, isPatchSurveyRunning])

  if (!survey?.sid) {
    return (
      <>
        <TopBar />
        <div
          style={{ height: '100vh' }}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <span
            style={{ width: 48, height: 48 }}
            className="loader mb-4"
          ></span>
          <h1 className="">Loading survey...</h1>
        </div>
      </>
    )
  }

  let topButtons = null
  if (
    !currentOpenPanel.panel ||
    currentOpenPanel.panel === SURVEY_PANELS.structure.panel
  ) {
    topButtons = (
      <div
        className="position-fixed"
        style={{
          left: '380px',
          top: '80px',
          zIndex: 3,
        }}
      >
        <a
          target="_blank"
          rel="noreferrer"
          href={survey.previewLink}
          className="preview-button mb-2 d-flex align-items-center justify-content-center cursor-pointer"
        >
          <EyeIcon className="text-black fill-current" />
        </a>
        {isSurveyActive && (
          <div
            onClick={() => setIsSurveyShareMenuOpen(true)}
            className="preview-button mb-2 d-flex align-items-center justify-content-center cursor-pointer"
          >
            <i className="ri-share-forward-line"></i>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <Toaster />
      <TopBar surveyId={surveyId} />
      <Container className="p-0" fluid>
        <div id="content" className="d-flex">
          <LeftSideBar surveyId={surveyId} />
          <div className="main-body position-relative">
            {topButtons}

            <div className="survey-part">
              {currentOpenPanel.panel &&
              currentOpenPanel.panel !== SURVEY_PANELS.structure.panel ? (
                <SurveySettings id={surveyId} />
              ) : (
                <Survey id={surveyId} />
              )}
            </div>
            {(!currentOpenPanel.panel ||
              currentOpenPanel.panel === SURVEY_PANELS.structure.panel) && (
              <div className="inner-wrap" ref={ref} />
            )}
            {isAddingQuestionOrGroup && (
              <div
                className="adding-question-group-wrapper"
                onClick={() => setIsAddingQuestionOrGroup(false)}
              />
            )}
          </div>
          <RightSideBar surveyId={surveyId} />
        </div>
        <FeedbackPopup />
      </Container>
    </>
  )
}
