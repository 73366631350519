import { useMemo, useState } from 'react'
import classNames from 'classnames'

import { AddIcon } from 'components/icons'
import {
  arrayDeleteItem,
  SCALE_1,
  SCALE_2,
  createBufferOperation,
  getAnswerExample,
  getQuestionExample,
  STATES,
  TOOLTIP_MESSAGES,
} from 'helpers'
import { useAppState, useBuffer } from 'hooks'
import { QuestionTypeInfo } from '../QuestionTypeInfo'
import { DISPLAY_ATTRIBUTES } from 'components/QuestionSettings/attributes'
import { Button } from 'components/UIComponents'
import { TooltipContainer } from 'components/TooltipContainer/TooltipContainer'

import { ArrayHorizontalTitles, ArrayVerticalTitles } from './'

const DRAG_ICON_SIZE = 22

export const ArrayQuestion = ({
  question: { answers = [], subquestions = [] } = {},
  question,
  handleUpdate,
  language,
  isFocused,
}) => {
  const { addToBuffer } = useBuffer()
  const [highestHeight1, setHighestHeight1] = useState(0)
  const [highestHeight2, setHighestHeight2] = useState(0)
  const [hoirzontalInfo1, setHoirzontalInfo1] = useState({})
  const [hoirzontalInfo2, setHorizontalInfo2] = useState({})
  const [subQuestionsHeight, setSubQuestionsHeight] = useState([])
  const [highestSubquestionWidth, setHighestSubquestiontWidth] = useState(0)
  const [arrayAnswersWidthScale1, setArrayAnswersWidthScale1] = useState([])
  const [arrayAnswersWidthScale2, setArrayAnswersWidthScale2] = useState([])
  const [verticalEntitiesInfo, setVerticalEntitiesInfo] = useState({})
  const [headersHeight, setHeadersHeight] = useState([0, 0])

  const [numberOfHorizontalEntities1, setNumberOfHorizontalEntities1] =
    useState(0)
  const [numberOfHorizontalEntities2, setNumberOfHorizontalEntities2] =
    useState(0)
  const [isSurveyActive] = useAppState(STATES.IS_SURVEY_ACTIVE)

  const highestHeight = useMemo(() => {
    return highestHeight1 > highestHeight2 ? highestHeight1 : highestHeight2
  }, [highestHeight1, highestHeight2])

  const setHighestHeight = (height, scale) => {
    if (scale === SCALE_1) {
      setHighestHeight1(height)
    } else {
      setHighestHeight2(height)
    }
  }

  const isArrayDualScale =
    question.questionThemeName === QuestionTypeInfo.ARRAY_DUAL_SCALE.theme

  const colName = [
    QuestionTypeInfo.ARRAY_NUMBERS.theme,
    QuestionTypeInfo.ARRAY_TEXT.theme,
    QuestionTypeInfo.ARRAY_COLUMN.theme,
  ].includes(question.questionThemeName)
    ? 'subquestion'
    : 'answer option'
  const handleOnDragEnd = (dropResult, entitiesInfo) => {
    if (!dropResult.destination) {
      return
    }

    const { itemsKey, scaleId, sortKey } = entitiesInfo

    const offsetIndex =
      scaleId === SCALE_1
        ? 0
        : question[itemsKey].filter((item) => item.scaleId === SCALE_1).length

    const updatedItems = reorderItems(
      question[itemsKey],
      dropResult.source.index + offsetIndex,
      dropResult.destination.index + offsetIndex,
      sortKey
    )

    if (itemsKey === 'subquestions') {
      const operation = createBufferOperation(question.qid)
        .subquestion()
        .update([...updatedItems])

      addToBuffer(operation)
    } else {
      const operation = createBufferOperation(question.qid)
        .answer()
        .update([...updatedItems])

      addToBuffer(operation)
    }

    handleUpdate({
      [itemsKey]: updatedItems,
    })
  }

  const reorderItems = (listItems, startIndex, endIndex, orderProp) => {
    const updatedList = [...listItems]
    const [removed] = updatedList.splice(startIndex, 1)
    updatedList.splice(endIndex, 0, removed)

    return updatedList.map((item, index) => {
      item[orderProp] = index + 1
      return item
    })
  }

  const addItem = (info) => {
    const { itemsKey, scaleId, titleKey } = info
    const item =
      titleKey === 'answer'
        ? getAnswerExample({
            qid: question.qid,
            language: language,
            scaleId,
            sortOrder: answers.length + 1 || 1,
          })
        : getQuestionExample({
            gid: question.gid,
            language: language,
            scaleId,
            sortOrder: subquestions?.length + 1 || 1,
            parentQid: question.qid,
          })

    let updatedItems = question[itemsKey] ? [...question[itemsKey]] : []

    updatedItems.push(item)

    let operation

    if (titleKey === 'answer') {
      operation = createBufferOperation(question.qid)
        .answer()
        .create([...updatedItems])
    } else {
      operation = createBufferOperation(question.qid)
        .subquestion()
        .create([...updatedItems])
    }

    addToBuffer(operation)
    handleUpdate({
      [itemsKey]: updatedItems,
    })
  }

  const handleUpdateL10ns = (value, entitiesInfo, index) => {
    const { items, itemsKey, titleKey, idKey } = entitiesInfo

    const itemToUpdate = items[index]
    const updatedItemIndex = question[itemsKey].findIndex(
      (item) => item[idKey] === itemToUpdate[idKey]
    )
    const item = question[itemsKey][updatedItemIndex]
    if (item.l10ns == undefined) {
      item.l10ns = {}
    }
    if (item.l10ns[language] == undefined) {
      item.l10ns[language] = {}
    }
    item.l10ns[language][titleKey] = value

    let operation

    if (titleKey === 'answer') {
      operation = createBufferOperation(question.qid)
        .answer()
        .update([...question[itemsKey]])
    } else {
      operation = createBufferOperation(question.qid)
        .subquestion()
        .update([...question[itemsKey]])
    }

    addToBuffer(operation)
    handleUpdate({ question })
  }

  const removeItem = (info, index) => {
    const { itemsKey, items, titleKey, idKey } = info
    let updatedItems = [...question[itemsKey]]
    const itemToRemove = items[index]
    const indexToRemove = updatedItems.findIndex(
      (item) => item[idKey] === itemToRemove[idKey]
    )

    updatedItems = arrayDeleteItem(updatedItems, indexToRemove)[0]

    const operation =
      titleKey === 'answer'
        ? createBufferOperation(itemToRemove[idKey]).answer().delete()
        : createBufferOperation(itemToRemove[idKey]).subquestion().delete()

    addToBuffer(operation)
    handleUpdate({
      [itemsKey]: updatedItems,
    })
  }

  const handleHeaderHeightChange = (height, index) => {
    headersHeight[index] = height

    setHeadersHeight(headersHeight)
  }

  const handleHeaderUpdate = (value, scaleId) => {
    const attributeName =
      scaleId === SCALE_1
        ? DISPLAY_ATTRIBUTES.SCALE_HEADER_A.attributeName
        : DISPLAY_ATTRIBUTES.SCALE_HEADER_B.attributeName

    question.attributes[attributeName] = {
      ...(question.attributes[attributeName] || {}),
      [language]: value,
    }

    const operation = createBufferOperation(question.qid)
      .questionAttribute()
      .update({
        [attributeName]: {
          [language]: value,
        },
      })

    addToBuffer(operation)
    handleUpdate({ question })
  }

  return (
    <>
      <div className="array-question d-flex gap-5" data-testid="array-question">
        <div className="d-flex">
          <div>
            <ArrayHorizontalTitles
              question={question}
              onDragEndCallback={handleOnDragEnd}
              highestSubquestionWidth={highestSubquestionWidth}
              isFocused={isFocused}
              arrayAnswersWidth={arrayAnswersWidthScale1}
              setArrayAnswersWidth={setArrayAnswersWidthScale1}
              dragIconSize={DRAG_ICON_SIZE}
              isArrayDualScale={isArrayDualScale}
              scaleId={SCALE_1}
              highestHeight={highestHeight}
              setHighestHeight={setHighestHeight}
              setNumberOfHorizontalEntities={setNumberOfHorizontalEntities1}
              setHorizontalInfo={setHoirzontalInfo1}
              handleUpdateL10ns={handleUpdateL10ns}
              removeItem={removeItem}
              handleHeaderUpdate={handleHeaderUpdate}
              handleHeaderHeightChange={handleHeaderHeightChange}
              headersHeight={headersHeight}
            />
            <ArrayVerticalTitles
              onDragEndCallback={handleOnDragEnd}
              language={language}
              question={question}
              isFocused={isFocused}
              highestWidth={highestSubquestionWidth}
              setHighestWidth={setHighestSubquestiontWidth}
              handleUpdate={handleUpdate}
              arrayAnswersWidth={arrayAnswersWidthScale1}
              dragIconSize={DRAG_ICON_SIZE}
              scaleId={SCALE_1}
              subQuestionsHeight={subQuestionsHeight}
              setSubQuestionsHeight={setSubQuestionsHeight}
              numberOfHorizontalEntities={numberOfHorizontalEntities1}
              addItem={addItem}
              removeItem={removeItem}
              handleUpdateL10ns={handleUpdateL10ns}
              setVerticalEntitiesInfo={setVerticalEntitiesInfo}
            />
          </div>
          <div
            className={classNames('pb-1 ms-2 me-4', {
              'd-none': !isFocused,
            })}
            style={{
              position: 'sticky',
              right: '0',
            }}
          >
            <TooltipContainer
              tip={TOOLTIP_MESSAGES.ACTIVE_DISABLED}
              showTip={isSurveyActive && colName === 'subquestion'}
              placement="left"
            >
              <Button
                onClick={() => addItem(hoirzontalInfo1)}
                style={{
                  height: '100%',
                  width: '50px',
                }}
                disabled={isSurveyActive && colName === 'subquestion'}
                className="add-item-button"
                testId="add-vertical-item-button"
              >
                <AddIcon />
              </Button>
            </TooltipContainer>
          </div>
        </div>
        {isArrayDualScale && (
          <div className="d-flex">
            <div>
              <ArrayHorizontalTitles
                question={question}
                handleUpdate={handleUpdate}
                onDragEndCallback={handleOnDragEnd}
                highestSubquestionWidth={highestSubquestionWidth}
                isFocused={isFocused}
                arrayAnswersWidth={arrayAnswersWidthScale2}
                setArrayAnswersWidth={setArrayAnswersWidthScale2}
                dragIconSize={DRAG_ICON_SIZE}
                isArrayDualScale={isArrayDualScale}
                scaleId={SCALE_2}
                highestHeight={highestHeight}
                setHighestHeight={setHighestHeight}
                setNumberOfHorizontalEntities={setNumberOfHorizontalEntities2}
                setHorizontalInfo={setHorizontalInfo2}
                removeItem={removeItem}
                handleUpdateL10ns={handleUpdateL10ns}
                handleHeaderUpdate={handleHeaderUpdate}
                handleHeaderHeightChange={handleHeaderHeightChange}
                headersHeight={headersHeight}
              />
              <ArrayVerticalTitles
                onDragEndCallback={handleOnDragEnd}
                language={language}
                question={question}
                isFocused={isFocused}
                highestWidth={highestSubquestionWidth}
                setHighestWidth={setHighestSubquestiontWidth}
                handleUpdate={handleUpdate}
                arrayAnswersWidth={arrayAnswersWidthScale2}
                dragIconSize={DRAG_ICON_SIZE}
                showContentEditor={false}
                scaleId={SCALE_2}
                subQuestionsHeight={subQuestionsHeight}
                numberOfHorizontalEntities={numberOfHorizontalEntities2}
                removeItem={removeItem}
                addItem={addItem}
                handleUpdateL10ns={handleUpdateL10ns}
                setVerticalEntitiesInfo={setVerticalEntitiesInfo}
              />
            </div>
            <div
              className={classNames('pb-1 ms-2 me-4', {
                'd-none': !isFocused,
              })}
            >
              <TooltipContainer
                tip={TOOLTIP_MESSAGES.ACTIVE_DISABLED}
                showTip={isSurveyActive && colName === 'subquestion'}
                placement={'left'}
              >
                <Button
                  onClick={() => addItem(hoirzontalInfo2)}
                  style={{ height: '100%', width: '50px' }}
                  disabled={isSurveyActive && colName === 'subquestion'}
                  className="add-item-button"
                  testId="add-vertical-item-button2"
                >
                  <AddIcon />
                </Button>
              </TooltipContainer>
            </div>
          </div>
        )}
      </div>
      <div
        style={{ marginLeft: highestSubquestionWidth + DRAG_ICON_SIZE }}
        className={classNames('mt-2 array-question array-question-footer', {
          'd-none': !isFocused,
        })}
      >
        <TooltipContainer
          tip={TOOLTIP_MESSAGES.ACTIVE_DISABLED}
          showTip={
            isSurveyActive && verticalEntitiesInfo.rowName === 'subquestion'
          }
          placement="left"
        >
          <Button
            className={classNames('add-item-button add-horizontal-item w-100')}
            onClick={() => addItem(verticalEntitiesInfo)}
            disabled={
              isSurveyActive && verticalEntitiesInfo.rowName === 'subquestion'
            }
            testId="add-horizontal-item-button"
          >
            <AddIcon />
          </Button>
        </TooltipContainer>
      </div>
    </>
  )
}
