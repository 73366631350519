import { useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import LogoIcon from '../../../assets/images/logo-icon-black.png'

import { useAuth, useAppState, useBuffer, useErrors, useSurvey } from 'hooks'
import {
  Entities,
  L10ns,
  RemoveHTMLTagsInString,
  STATES,
  createBufferOperation,
  URLS,
} from 'helpers'
import { AddQuestion } from 'components/Survey/AddQuestion'
import { PublishSettings } from 'components/PublishSettings/PublishSettings'
import { ContentEditor } from 'components'
import { TopBarQuestionInserter } from './TopBarQuestionInserter'
import { ButtonUpgrade } from './Button/ButtonUpgrade'
//import { ButtonBackToClassicEditor } from './Button/ButtonBackToClassicEditor'

export const TopBar = ({ surveyId }) => {
  const { survey, update, surveyList } = useSurvey(surveyId)
  const { getError } = useErrors()
  const [surveyTitleIsFocused, setSurveyTitleIsFocused] = useState(false)
  const navigate = useNavigate()
  const { operationsBuffer, addToBuffer } = useBuffer()
  const [, setFocusedQuestionGroup] = useState({})
  const [saveState] = useAppState(STATES.SAVE_STATUS)
  const titleRef = useRef(null)
  const titleSelectOffset = 40
  const [surveyTitleWidth, setSurveyTitleWidth] = useState(0)

  const [isAddingQuestionOrGroup] = useAppState(
    STATES.IS_ADDING_QUESTION_OR_GROUP,
    false
  )

  const { userId } = useAuth()

  const operationsLength = useMemo(() => {
    return operationsBuffer?.getOperations()?.length
  }, [operationsBuffer.getOperations()?.length])

  const surveyTitle = L10ns({
    prop: 'title',
    language: survey.language,
    l10ns: survey.languageSettings,
  })

  const onTitleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const onSurveyTitleChange = (title) => {
    let updatedTitle = RemoveHTMLTagsInString(title).replaceAll('&nbsp;', '')
    updatedTitle = (updatedTitle.trim() === '') === '' ? '' : updatedTitle

    const operation = createBufferOperation(survey.sid)
      .languageSetting()
      .update({
        [survey.language]: {
          title: updatedTitle,
        },
      })

    addToBuffer(operation)
    update({
      languageSettings: {
        ...survey.languageSettings,
        [survey.language]: {
          ...survey.languageSettings[survey.language],
          title: updatedTitle,
        },
      },
    })
  }

  const handleSurveySwitch = async (e) => {
    //what is going on here?
    navigate(`/survey/${e.target.value}`)
  }

  const classicEditorUrl = URLS.SURVEY_OVERVIEW + '?surveyid=' + surveyId

  useEffect(() => {
    setFocusedQuestionGroup(null)
  }, [survey.sid])

  useEffect(() => {
    setSurveyTitleWidth(titleRef.current?.clientWidth)
  }, [titleRef.current?.innerHTML])

  return (
    <div className="top-bar d-flex w-100 justify-content-between">
      <div className="top-bar-left d-flex ps-2 ms-1">
        <a
          className="top-bar-brand"
          data-testid="logo-a-tag"
          href={classicEditorUrl}
        >
          <img className="logo" src={LogoIcon} height={34} /> LimeSurvey
        </a>
        <span
          className="d-flex align-items-center"
          style={{
            paddingLeft: '60px',
          }}
        >
          <AddQuestion />
        </span>
      </div>
      <div className="top-bar-middle d-flex flex-grow-1 justify-content-center align-items-center">
        <div
          data-error={getError(survey.sid, Entities.languageSetting)}
          className="d-flex align-items-center text-align-center top-bar-select align-middle"
        >
          <div className="d-flex justify-content-center">
            <ContentEditor
              value={surveyTitle}
              placeholder="Survey Title"
              update={onSurveyTitleChange}
              contentRef={titleRef}
              className="survey-title-content-editor"
              onBlur={() => {
                setSurveyTitleIsFocused(false)
                setSurveyTitleWidth(titleRef.current?.clientWidth)
              }}
              onFocus={() => setSurveyTitleIsFocused(true)}
              onKeyDown={onTitleKeyDown}
              noPermissionDisabled={true}
              toolTipPlacement={'bottom'}
              testId="topbar-survey-title-content-editor"
            />

            <Form.Select
              aria-label="Default select example"
              onChange={handleSurveySwitch}
              value={surveyId}
              className={classNames('form-select-top-bar', {
                'd-none': surveyTitleIsFocused,
              })}
              style={{
                width: surveyTitleWidth + titleSelectOffset,
                height: titleRef.current?.offsetHeight,
              }}
            >
              {surveyList.map((survey) => {
                let title = L10ns({
                  prop: 'title',
                  language: survey.language,
                  l10ns: survey.languageSettings,
                })
                return (
                  <option key={survey.sid} value={survey.sid}>
                    {RemoveHTMLTagsInString(title)}
                  </option>
                )
              })}
            </Form.Select>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <span className="small text-muted me-1">
          {process.env.REACT_APP_DEV_MODE && '(Dev Mode) '}
          {process.env.REACT_APP_DEMO_MODE && '(Demo Mode) '}
        </span>
        <div className="d-flex align-items-center me-2">
          <p
            className={classNames(`m-0 me-2 auto-saved`, {
              'text-success': operationsBuffer.isEmpty(),
              'text-secondary': !operationsBuffer.isEmpty(),
            })}
          >
            {saveState}
          </p>
        </div>
        {/*<ButtonBackToClassicEditor className="me-2" surveyId={surveyId} />*/}
        <PublishSettings
          className="me-2"
          survey={survey}
          update={update}
          operationsLength={operationsLength}
        />
        {userId == 1 && <ButtonUpgrade className="me-2" />}
        <div>
          {isAddingQuestionOrGroup && (
            <TopBarQuestionInserter surveyID={survey.sid} />
          )}
        </div>
      </div>
    </div>
  )
}
