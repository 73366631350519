import { Form } from 'react-bootstrap'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

import { useAppState } from 'hooks'
import { dayJs, STATES, TOOLTIP_MESSAGES } from 'helpers'

import { TooltipContainer } from 'components'

export const DateTimePickerComponent = ({
  labelText,
  defaultValue,
  update = () => {},
  minDate = dayJs('2000-04-17T15:30'),
  maxDate = dayJs('2050-04-17T15:30'),
  noPermissionDisabled = false,
  noAccessDisabled = false,
  closeOnSelect = false,
}) => {
  const [hasSurveyUpdatePermission] = useAppState(
    STATES.HAS_SURVEY_UPDATE_PERMISSION
  )
  const disabled =
    (noPermissionDisabled && !hasSurveyUpdatePermission) || noAccessDisabled
  const [siteSettings] = useAppState(STATES.SITE_SETTINGS)
  const timezone = siteSettings?.timezone ? siteSettings.timezone : null

  const handleOnAccept = (value) => {
    update(value.toJSON())
  }

  return (
    <div data-testid="data-time-picker">
      {labelText && <Form.Label>{labelText}</Form.Label>}
      <div>
        <TooltipContainer
          tip={TOOLTIP_MESSAGES.NO_PERMISSION}
          showTip={disabled}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              ampm={false}
              minDate={dayJs(minDate)}
              maxDate={dayJs(maxDate)}
              defaultValue={dayJs(defaultValue)}
              timezone={timezone}
              onAccept={handleOnAccept}
              disabled={disabled}
              closeOnSelect={closeOnSelect}
            />
          </LocalizationProvider>
        </TooltipContainer>
      </div>
    </div>
  )
}
